import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { memo, useRef } from 'react';
import { DRACOLoader } from 'three-stdlib'

import * as THREE from 'three';

const Sprout = ({
  friendsieState,
  headSize,
  downloadingModel,
  // setSproutAnimation
}) => {
  const group = useRef();
  // let traitData = getTraitData(friendsieState.index, 'Sprout');
  // let headData = getTraitData(headState.index, 'Head');
  let modelData = useGLTF(friendsieState.asset_url, false, true, (loader) => {
    loader.setDRACOLoader(new DRACOLoader())
  });

  // setSproutAnimation(modelData.animations);
  // const { nodes, materials, animations } = useGLTF('/models/Testing2422/Sproutes/Medium/Sproutes_Angel_M.glb');
  // const { ref, actions, names } = useAnimations(animations, group);

  let mixer;
  if (modelData && modelData.animations.length && !downloadingModel) {
    mixer = new THREE.AnimationMixer(modelData.scene);
    modelData.animations.forEach((clip) => {
      const action = mixer.clipAction(clip);
      action.play();
    });
  }

  useFrame((state, delta) => {
    mixer?.update(delta);
  });

  return (
    <group ref={group} dispose={null}>
      <primitive object={modelData.scene} />
      {/* <primitive object={modelData.nodes.Root} /> */}
      {/* <skinnedMesh
        geometry={modelData.nodes[traitData.geometry].geometry}
        material={modelData.materials[traitData.material]}
        skeleton={modelData.nodes[traitData.geometry].skeleton}
      /> */}
    </group>
  );
};

export default Sprout;
