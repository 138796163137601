import { useGLTF, useTexture } from '@react-three/drei';
import axios from 'axios';
import NoneIcon from '../assets/images/none-icon.png';

export const traitDisplayName = (trait) => {
  switch (trait) {
    case 'sprout':
      return 'SPROUT';
    case 'face':
      return 'VIBE';
    case 'head':
      return 'HEAD';
    case 'body':
      return 'BODY';
    case 'backpiece':
      return 'BACK';
    case 'accessory':
      return 'POWER';
    case 'shoe':
      return 'KICKS';

    default:
      break;
  }
};

// friendsieState, setPreviousFriendsieState
export const randomizeFriendsie = (availableAssets) => {
  if (!availableAssets) return null;
  // setPreviousFriendsieState(friendsieState);
  let backpiece = randomObjectKey(availableAssets.backpiece);
  let body = randomObjectKey(availableAssets.body);
  let accessory = randomObjectKey(availableAssets.hand_accessory);
  let shoe = randomObjectKey(availableAssets.shoe);
  let head = randomObjectKey(availableAssets.head);

  // // get the attributes of the random head
  let headFixed = head.attributes.includes('variable_face') ? 'variable' : 'fixed';
  let headSize = head.attributes.filter((value) => value === 'small' || value === 'medium' || value === 'large')[0];
  let headColor = headFixed === 'fixed' ? null : head.attributes.includes('light') ? 'light' : 'dark';
  let headNoSprout = head.attributes.includes('no_sprouts') ? true : false;
  let fixedModel = head.attributes.includes('fixed_model') ? true : false;

  // get sprout based on head size
  let sprout = headNoSprout ? '' : randomObjectKey(availableAssets[`sprouts_${headSize}`]);

  // only variable heads have a color. If fixed face, set random dark face as as placeholder in face state. It won't be used anyway
  let faceColor = headColor ? (headColor === 'light' ? 'dark' : 'light') : null;
  let face = faceColor ? randomObjectKey(availableAssets[`face_${faceColor}`]) : '';

  return {
    sprout: sprout,
    body: body,
    backpiece: backpiece,
    accessory: accessory,
    shoe: shoe,
    head: head,
    headColor: headColor,
    headFixed: headFixed,
    headSize: headSize,
    headNoSprout: headNoSprout,
    fixedModel: fixedModel,
    face: face,
  };
};

/**
 * render model based on the trait IDs in URL params
 * @param availableAssets available asesets from api
 * @param URLSearchParams params from the URL with body part IDs
 */
export const createModelFromUrl = (availableAssets, URLSearchParams) => {
  if (!availableAssets) return null;

  let headExists = findIdData(URLSearchParams.get('head'), availableAssets.head);
  let head = headExists ? headExists : randomObjectKey(availableAssets.head);

  let bodyExists = findIdData(URLSearchParams.get('body'), availableAssets.body);
  let body = bodyExists ? bodyExists : randomObjectKey(availableAssets.body);

  let backPieceId = URLSearchParams.get('backpiece');
  let backpieceExists = findIdData(backPieceId, availableAssets.backpiece);
  let backpiece = backpieceExists ? backpieceExists : backPieceId === 'none' ? '' : randomObjectKey(availableAssets.backpiece);

  let accessoryId = URLSearchParams.get('accessory');
  let accessoryExists = findIdData(accessoryId, availableAssets.hand_accessory);
  let accessory = accessoryExists ? accessoryExists : accessoryId === 'none' ? '' : randomObjectKey(availableAssets.hand_accessory);

  let shoeId = URLSearchParams.get('shoe');
  let shoeExists = findIdData(URLSearchParams.get('shoe'), availableAssets.shoe);
  let shoe = shoeExists ? shoeExists : shoeId === 'none' ? '' : randomObjectKey(availableAssets.shoe);

  // sprout and face depends on the selected head, so logic required if ids not provided
  let headFixed = head.attributes.includes('variable_face') ? 'variable' : 'fixed';
  let headSize = head.attributes.filter((value) => value === 'small' || value === 'medium' || value === 'large')[0];
  let headColor = headFixed === 'fixed' ? null : head.attributes.includes('light') ? 'light' : 'dark';
  let headNoSprout = head.attributes.includes('no_sprouts') ? true : false;

  let fixedModel = head.attributes.includes('fixed_model') ? true : false;

  // get sprout based on head size
  let sproundId = URLSearchParams.get('sprout');
  let sproutExists = headNoSprout ? '' : findIdData(sproundId, availableAssets[`sprouts_${headSize}`]);
  let sprout = headNoSprout ? '' : sproutExists ? sproutExists : sproundId === 'none' ? '' : randomObjectKey(availableAssets[`sprouts_${headSize}`]);

  // only variable heads have a color. If fixed face, set random dark face as placeholder in face state. It won't be used anyway
  let inverseFaceColor = headColor ? (headColor === 'light' ? 'dark' : 'light') : '';
  // get face based on head color
  let faceExists = inverseFaceColor ? findIdData(URLSearchParams.get('face'), availableAssets[`face_${inverseFaceColor}`]) : inverseFaceColor;

  let face = faceExists
    ? faceExists
    : inverseFaceColor
    ? randomObjectKey(availableAssets[`face_${inverseFaceColor}`])
    : randomObjectKey(availableAssets[`face_dark`]);

  return {
    sprout: sprout,
    body: body,
    backpiece: backpiece,
    accessory: accessory,
    shoe: shoe,
    head: head,
    headColor: headColor,
    headFixed: headFixed,
    headSize: headSize,
    headNoSprout: headNoSprout,
    fixedModel: fixedModel,
    face: face,
  };
};

export const buildModelFromSeed = (availableAssets, seedResult) => {
  let head = availableAssets.head[seedResult.head] ? availableAssets.head[seedResult.head] : null;
  // get head attributes
  let headFixed = head ? (head.attributes.includes('variable_face') ? 'variable' : 'fixed') : null;
  let headSize = head ? head.attributes.filter((value) => value === 'small' || value === 'medium' || value === 'large')[0] : null;
  let headColor = head ? (headFixed === 'fixed' ? null : head.attributes.includes('light') ? 'light' : 'dark') : null;
  let headNoSprout = head ? (head.attributes.includes('no_sprouts') ? true : false) : null;
  // one of one head
  let fixedModel = head ? (head.attributes.includes('fixed_model') ? true : false) : null;

  // body
  let body = availableAssets.body[seedResult.body] ? availableAssets.body[seedResult.body] : '';

  // backpiece
  let backpiece = availableAssets.backpiece[seedResult.backpiece] ? availableAssets.backpiece[seedResult.backpiece] : '';

  // hand accessory
  let accessory = availableAssets.hand_accessory[seedResult.hand] ? availableAssets.hand_accessory[seedResult.hand] : '';

  // shoe
  let shoe = availableAssets.shoe[seedResult.shoe] ? availableAssets.shoe[seedResult.shoe] : '';

  // lookup dependent traits data from available assets
  let sprout = headNoSprout ? '' : availableAssets[`sprouts_${headSize}`][seedResult.sprout] ? availableAssets[`sprouts_${headSize}`][seedResult.sprout] : '';

  // only variable heads have a color. If fixed face, set random dark face as placeholder in face state. It won't be used anyway
  let inverseFaceColor = headColor ? (headColor === 'light' ? 'dark' : 'light') : '';
  // get face based on head color
  let face = inverseFaceColor ? availableAssets[`face_${inverseFaceColor}`][seedResult.face] : null;

  // set as current friendsieState
  let initialFriendsieState = {
    sprout: sprout,
    body: body,
    backpiece: backpiece,
    accessory: accessory,
    shoe: shoe,
    head: head,
    headColor: headColor,
    headFixed: headFixed,
    headSize: headSize,
    headNoSprout: headNoSprout,
    fixedModel: fixedModel,
    face: face,
  };

  return initialFriendsieState;
};

// user has been in builder before
export const restoreFromPreviousSeed = (seedResult, availableAssets) => {
  // lookup traits to get their data from the available assets if it exist
  // head
  let headExists = availableAssets.head[seedResult.data.head] ? availableAssets.head[seedResult.data.head] : null;
  let head = headExists ? headExists : randomObjectKey(availableAssets.head);

  // get head attributes
  let headFixed = head.attributes.includes('variable_face') ? 'variable' : 'fixed';
  let headSize = head.attributes.filter((value) => value === 'small' || value === 'medium' || value === 'large')[0];
  let headColor = headFixed === 'fixed' ? null : head.attributes.includes('light') ? 'light' : 'dark';
  let headNoSprout = head.attributes.includes('no_sprouts') ? true : false;
  // one of one head
  let fixedModel = head.attributes.includes('fixed_model') ? true : false;
  // body
  let bodyExists = availableAssets.body[seedResult.data.body] ? availableAssets.body[seedResult.data.body] : '';
  let body = bodyExists ? bodyExists : randomObjectKey(availableAssets.body);

  // backpiece
  let backpieceExists = availableAssets.backpiece[seedResult.data.backpiece] ? availableAssets.backpiece[seedResult.data.backpiece] : '';
  let backpiece = backpieceExists ? backpieceExists : randomObjectKey(availableAssets.backpiece);

  // hand accessory
  let accessoryExists = availableAssets.hand_accessory[seedResult.data.hand] ? availableAssets.hand_accessory[seedResult.data.hand] : '';
  let accessory = accessoryExists ? accessoryExists : randomObjectKey(availableAssets.hand_accessory);

  // shoe
  let shoeExists = availableAssets.shoe[seedResult.data.shoe] ? availableAssets.shoe[seedResult.data.shoe] : '';
  let shoe = shoeExists ? shoeExists : randomObjectKey(availableAssets.shoe);

  // lookup dependent traits data from available assets
  let sprout = headNoSprout
    ? ''
    : availableAssets[`sprouts_${headSize}`][seedResult.data.sprout]
    ? availableAssets[`sprouts_${headSize}`][seedResult.data.sprout]
    : '';

  // only variable heads have a color. If fixed face, set random dark face as placeholder in face state. It won't be used anyway
  let inverseFaceColor = headColor ? (headColor === 'light' ? 'dark' : 'light') : '';
  // get face based on head color
  let faceExists = inverseFaceColor ? availableAssets[`face_${inverseFaceColor}`][seedResult.data.face] : inverseFaceColor;

  let face = faceExists
    ? faceExists
    : inverseFaceColor
    ? randomObjectKey(availableAssets[`face_${inverseFaceColor}`])
    : randomObjectKey(availableAssets[`face_dark`]);

  // set as current friendsieState
  let initialFriendsieState = {
    sprout: sprout,
    body: body,
    backpiece: backpiece,
    accessory: accessory,
    shoe: shoe,
    head: head,
    headColor: headColor,
    headFixed: headFixed,
    headSize: headSize,
    headNoSprout: headNoSprout,
    fixedModel: fixedModel,
    face: face,
  };

  return initialFriendsieState;
};

/**
 * create url object from the currently selected traits
 * @param friendsieState current friendsie traits stored in state
 */
export const createUrlFromState = (friendsieState) => {
  let headId = friendsieState.head.id ? friendsieState.head.id : 'none';
  let bodyId = friendsieState.body.id ? friendsieState.body.id : 'none';
  let sproutId = friendsieState.sprout.id ? friendsieState.sprout.id : 'none';
  let backpieceId = friendsieState.backpiece.id ? friendsieState.backpiece.id : 'none';
  let accessoryId = friendsieState.accessory.id ? friendsieState.accessory.id : 'none';
  let shoeId = friendsieState.shoe.id ? friendsieState.shoe.id : 'none';
  let faceId = friendsieState.face.id ? friendsieState.face.id : 'none';

  return {
    head: headId,
    body: bodyId,
    sprout: sproutId,
    backpiece: backpieceId,
    accessory: accessoryId,
    shoe: shoeId,
    face: faceId,
  };
};

// search through object ids to find matching id and return object
export const findIdData = (traitId, availableAssets) => {
  if (!traitId) return null;
  let result = Object.keys(availableAssets).find((obj) => {
    return availableAssets[obj].id === traitId;
  });
  // id exists in object as key, so return that object
  if (result) {
    return availableAssets[traitId];
  } else {
    return null;
  }
};

export const randomObjectKey = (obj) => {
  let keys = Object.keys(obj);
  return obj[keys[(keys.length * Math.random()) << 0]];
};

/**
 * Logic to check if face and head are both the same color. Only called on HEAD state change
 * @param traitData trait object - newly selected trait option
 */
export const checkHeadFaceLogic = (ALLMODELDATA, traitData, friendsieState) => {
  // get new head attributes
  let newHeadFixed = traitData.attributes.includes('variable_face') ? 'variable' : 'fixed';
  let newHeadSize = traitData.attributes.filter((value) => value === 'small' || value === 'medium' || value === 'large')[0];
  let newHeadColor = newHeadFixed === 'fixed' ? null : traitData.attributes.includes('light') ? 'light' : 'dark';
  let newHeadNoSprout = traitData.attributes.includes('no_sprouts') ? true : false;

  // one of one heads
  let fixedModel = traitData.attributes.includes('fixed_model') ? true : false;

  // random light and dark selections in case head color switched
  const newFace = newHeadColor
    ? newHeadColor === 'dark'
      ? randomObjectKey(ALLMODELDATA[`face_light`])
      : randomObjectKey(ALLMODELDATA[`face_dark`])
    : friendsieState.face;

  // get new sprout size to match new head
  const newSproutId = friendsieState.sprout.name
    ? Object.keys(ALLMODELDATA[`sprouts_${newHeadSize}`]).find((key) => ALLMODELDATA[`sprouts_${newHeadSize}`][key].name === friendsieState.sprout.name)
    : '';

  const newSprout = newSproutId ? ALLMODELDATA[`sprouts_${newHeadSize}`][newSproutId] : '';

  // Light face goes with dark head, and vice versa. If  color is not inverse, set face to new default
  if (friendsieState.face.part && friendsieState.face.part === `face_${newHeadColor}`) {
    return {
      ...friendsieState,
      head: traitData,
      face: newFace,
      headColor: newHeadColor,
      headFixed: newHeadFixed,
      headSize: newHeadSize,
      headNoSprout: newHeadNoSprout,
      fixedModel: fixedModel,
      sprout: newSprout,
    };
  } else {
    return {
      ...friendsieState,
      head: traitData,
      headColor: newHeadColor,
      headFixed: newHeadFixed,
      headSize: newHeadSize,
      headNoSprout: newHeadNoSprout,
      fixedModel: fixedModel,
      sprout: newSprout,
    };
  }
};  

// preload all the assets given
export const statePreloader = (friendsieState) => {
  Object.keys(friendsieState).forEach((key) => {
    if (friendsieState[key] && friendsieState[key].asset_url) {
      key === 'face' ? useTexture.preload(friendsieState[key].asset_url) : useGLTF.preload(friendsieState[key].asset_url, false);
      // console.log(`${key} - ${friendsieState[key].asset_url} preloaded`);
    }
  });
};

export const traitCategoryLogic = (ALLMODELDATA, traitCategory, friendsieState) => {
  // create an array from each object trait

  let newOptions;
  // add empty option
  let emptyOption = {
    id: '',
    part: null, // category is dynamically created in some cases
    name: 'None',
    attributes: [],
    preview_url: NoneIcon,
    asset_url: null,
    available: true,
  };

  switch (traitCategory) {
    case 'sprout':
      // category
      emptyOption.part = `sprouts_${friendsieState.headSize}`;

      newOptions = Object.keys(ALLMODELDATA[`sprouts_${friendsieState.headSize}`]).map((key) => {
        return ALLMODELDATA[`sprouts_${friendsieState.headSize}`][key];
      });

      // add empty option
      newOptions.unshift(emptyOption);

      return newOptions;

    // if fixed head, face options won't be shown anyway
    case 'face':
      // set face options as opposite the color of the head
      let faceColor = friendsieState.headColor ? (friendsieState.headColor === 'dark' ? 'light' : 'dark') : null;
      return friendsieState.headColor
        ? Object.keys(ALLMODELDATA[`face_${faceColor}`]).map((key) => {
            return ALLMODELDATA[`face_${faceColor}`][key];
          })
        : null;
    case 'accessory':
      // category
      emptyOption.part = `hand_accessory`;

      newOptions = Object.keys(ALLMODELDATA[`hand_accessory`]).map((key) => {
        return ALLMODELDATA[`hand_accessory`][key];
      });

      // add empty option
      newOptions.unshift(emptyOption);

      return newOptions;

    case 'backpiece':
      // category
      emptyOption.part = traitCategory;

      newOptions = Object.keys(ALLMODELDATA[traitCategory]).map((key) => {
        return ALLMODELDATA[traitCategory][key];
      });

      // add empty option
      newOptions.unshift(emptyOption);

      return newOptions;

    case 'shoe':
      // category
      emptyOption.part = traitCategory;

      newOptions = Object.keys(ALLMODELDATA[traitCategory]).map((key) => {
        return ALLMODELDATA[traitCategory][key];
      });

      // add empty option
      newOptions.unshift(emptyOption);

      return newOptions;

    default:
      return Object.keys(ALLMODELDATA[traitCategory]).map((key) => {
        return ALLMODELDATA[traitCategory][key];
      });
  }
};
