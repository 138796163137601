// import { Cloud, Sky, useHelper } from '@react-three/drei';
import { memo, useRef } from 'react';
import { useControls, folder } from 'leva';
import SkyDome from './SkyDome';
import ReflectivePool from './ReflectivePool';
import LeftMountain from './LeftMountain';
import RightMountain from './RightMountain';
import GreenHill from './GreenHill';
import * as THREE from 'three';
import Fireflies from './Fireflies';

const SceneLighting = memo(() => {
  const directionalLightRefOne = useRef();
  const directionalLightRefTwo = useRef();
  const directionalLightRefThree = useRef();
  const directionalLightRefFour = useRef();

  // useHelper(directionalLightRefOne, DirectionalLightHelper, 'cyan');
  // useHelper(directionalLightRefTwo, DirectionalLightHelper, 0xff0000);
  // useHelper(directionalLightRefThree, DirectionalLightHelper, 'cyan');
  // useHelper(directionalLightRefFour, DirectionalLightHelper, 'cyan');

  // const {
  //   dIntensityOne,
  //   dPositionOne,
  //   dColor,
  //   dIntensityTwo,
  //   dPositionTwo,
  //   dIntensityThree,
  //   dPositionThree,
  //   hemisphereIntensity,
  //   hemisphereColor,
  //   dIntensityFour,
  //   dPositionFour,
  //   ambientIntensity,
  //   ambientColor,
  // } = useControls({
  //   DirectionalLightFront: folder({
  //     dIntensityOne: { value: 0.5, min: 0, max: 10, step: 0.1 },
  //     // dIntensityOne: { value: 2.25, min: 0, max: 10, step: 0.1 },
  //     dPositionOne: { value: [-0.5, 2.5, 5], min: -0.1, max: 10 },
  //     dColor: { value: '#fff', label: 'color' },
  //   }),
  //   HemisphereLight: folder({
  //     hemisphereIntensity: { value: 0.25, min: 0, max: 4, step: 0.01 },
  //     // hemisphereIntensity: { value: 0.5, min: 0, max: 4, step: 0.01 },
  //     hemisphereColor: { value: '#fff', label: 'color' },
  //   }),
  //   AmbientLight: folder({
  //     ambientIntensity: { value: 0.25, min: 0, max: 2, step: 0.01 },
  //     // ambientIntensity: { value: 0.5, min: 0, max: 2, step: 0.01 },
  //     ambientColor: { value: '#fff', label: 'color' },
  //     // }),
  //   }),
  //   DirectionLighhtBack: folder({
  //     dIntensityTwo: { value: 0, min: 0, max: 4, step: 0.1 },
  //     // dIntensityTwo: { value: 1, min: 0, max: 4, step: 0.1 },
  //     dPositionTwo: { value: [-0.5, 2.5, -5], min: -0.1, max: 10 },
  //     // textColor: { value: '#fff', label: 'color' },
  //   }),
  //   DirectionalLightLeft: folder({
  //     dIntensityThree: { value: 0, min: 0, max: 4, step: 0.1 },
  //     // dIntensityThree: { value: 1, min: 0, max: 4, step: 0.1 },
  //     dPositionThree: { value: [-2.5, 2.5, 0], min: -0.1, max: 10 },
  //     // textColor: { value: '#fff', label: 'color' },
  //   }),
  //   DirectionalLightRight: folder({
  //     dIntensityFour: { value: 0, min: 0, max: 4, step: 0.1 },
  //     // dIntensityFour: { value: 1, min: 0, max: 4, step: 0.1 },
  //     dPositionFour: { value: [2.5, 2.5, 0], min: -0.1, max: 10 },
  //     // textColor: { value: '#fff', label: 'color' },
  //   }),
  // });

  return (
    <group dispose={null}>
      <hemisphereLight intensity={0.25} color={'#fff'} />
      <ambientLight intensity={0.25} color={'#fff'} />
      <directionalLight
        color={'#fff'}
        ref={directionalLightRefOne}
        intensity={0.5}
        position={[-0.5, 2.5, 5]}
        shadow-camera-left={5 * -100}
        shadow-camera-bottom={5 * -100}
        shadow-camera-right={5 * 100}
        shadow-camera-top={5 * 100}
        shadow-camera-near={10}
        shadow-camera-far={500}
        shadow-bias={-0.0001}
        shadow-mapSize-height={1024}
        shadow-mapSize-width={1024}
        castShadow
      />
      {/* <directionalLight color={0xffffff} ref={directionalLightRefTwo} intensity={dIntensityTwo} position={dPositionTwo} />
      <directionalLight color={0xffffff} ref={directionalLightRefThree} intensity={dIntensityThree} position={dPositionThree} />
      <directionalLight color={0xffffff} ref={directionalLightRefFour} intensity={dIntensityFour} position={dPositionFour} /> */}
      {/* <hemisphereLight intensity={0.25} /> */}

      {/* <ambientLight intensity={0.25} /> */}

      {/* <directionalLight
        color={0xffffff}
        ref={directionalLightRefOne}
        intensity={1.5}
        position={[-0.5, 2.5, 5]}
        shadow-camera-left={5 * -100}
        shadow-camera-bottom={5 * -100}
        shadow-camera-right={5 * 100}
        shadow-camera-top={5 * 100}
        shadow-camera-near={10}
        shadow-camera-far={500}
        shadow-bias={-0.0001}
        shadow-mapSize-height={1024}
        shadow-mapSize-width={1024}
        castShadow
      /> */}
      {/* <directionalLight color={0xffffff} ref={directionalLightRefTwo} intensity={1} position={[-0.5, 2.5, -5]} />
      <directionalLight color={0xffffff} ref={directionalLightRefThree} intensity={1} position={[-2.5, 2.5, 0]} />
      <directionalLight color={0xffffff} ref={directionalLightRefFour} intensity={1} position={[2.5, 2.5, 0]} /> */}

      {/* <pointLight position={[10, 10, 10]} /> */}

      {/* <directionalLight color={0xffffff} ref={directionalLightRefThree} intensity={dIntensityThree} position={dPositionThree} castShadow /> */}
      {/* <spotLight position={[-0.5, 5, 5]} angle={0.15} penumbra={1} intensity={10} castShadow shadow-mapSize={[2048, 2048]} /> */}
      {/* <Sky azimuth={0.5} turbidity={7.5} rayleigh={0.4} inclination={0.6} sunPosition={[-10, 50, 100]} /> */}
      {/* <pointLight position={[10, 10, 10]} /> */}
    </group>
  );
});

const World = () => {
  return (
    <group dispose={null}>
      <LeftMountain />
      <RightMountain />

      <GreenHill />

      <Fireflies />

      <SceneLighting />
      <SkyDome />
      {/* <Mountain /> */}
      <ReflectivePool />
    </group>
  );
};

export default memo(World);
