import React, { memo, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

const RightMountain = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/models/world/LeftMountains.glb', false);
  const { actions } = useAnimations(animations, group);
  return (
    //  position={[-30, 0, -10]} scale={[20, 20, 20]}
    <group ref={group} {...props} dispose={null}>
      <mesh
        name="Cylindrical_Mountain far left"
        castShadow
        receiveShadow
        geometry={nodes.Cylindrical_Mountain.geometry}
        material={nodes.Cylindrical_Mountain.material}
        position={[-3.5, -0.4, -2]}
        scale={0.55}
      />
      <mesh
        name="Cylindrical_Mountain far left"
        castShadow
        receiveShadow
        geometry={nodes.Cylindrical_Mountain.geometry}
        material={nodes.Cylindrical_Mountain.material}
        position={[-5, -0.2, -5]}
        scale={0.55}
      />
      <mesh
        name="Cylindrical_Mountain001 second left"
        castShadow
        receiveShadow
        geometry={nodes.Cylindrical_Mountain001.geometry}
        material={nodes.Cylindrical_Mountain001.material}
        position={[-3.5, -0.3, -4]}
        scale={0.4}
      />
      <mesh
        name="Spherical_Mountain001"
        castShadow
        receiveShadow
        geometry={nodes.Spherical_Mountain001.geometry}
        material={materials['Spherical Mountain']}
        position={[-1.75, -0.5, -2.5]}
        scale={[0.5, 0.45, 0.5]}
      />
      <mesh
        name="Spherical_Mountain001"
        castShadow
        receiveShadow
        geometry={nodes.Spherical_Mountain001.geometry}
        material={materials['Spherical Mountain']}
        position={[-1.5, -0.7, -4]}
        scale={[0.5, 0.45, 0.5]}
      />
      <mesh
        name="Cylindrical_Mountain002 back cylinder"
        castShadow
        receiveShadow
        geometry={nodes.Cylindrical_Mountain002.geometry}
        material={nodes.Cylindrical_Mountain002.material}
        position={[-3.15, -0.14, -5]}
        scale={[0.5, 0.6, 0.5]}
      />
      <mesh
        name="Cylindrical_Mountain003"
        castShadow
        receiveShadow
        geometry={nodes.Cylindrical_Mountain003.geometry}
        material={nodes.Cylindrical_Mountain003.material}
        position={[-1.85, -0.2, -4]}
        scale={[0.25, 0.3, 0.25]}
      />
      <mesh
        name="Cylindrical_Mountain003"
        castShadow
        receiveShadow
        geometry={nodes.Cylindrical_Mountain003.geometry}
        material={nodes.Cylindrical_Mountain003.material}
        position={[-1.65, -0.2, -3]}
        scale={[0.25, 0.3, 0.25]}
      />
    </group>
  );
};

export default memo(RightMountain);
