import React, { memo, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

const LeftMountain = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/models/world/RightMountains.glb', false);
  const { actions } = useAnimations(animations, group);

  const scaleMultiplier = 5;
  return (
    //   scale={[60, 60, 60]} // position={[-40, 0, 0]}
    //  position={[0.45, -0.06, 0]}
    // scale={[30, 30, 30]}
    <group ref={group} {...props} dispose={null}>
      <mesh
        name="Spherical_Mountain first circle"
        castShadow
        receiveShadow
        geometry={nodes.Spherical_Mountain.geometry}
        material={nodes.Spherical_Mountain.material}
        position={[2.25, -0.35, -1]}
        // scale={[0.07, 0.05, 0.07]}
        scale={[0.35, 0.25, 0.35]}
      />
      <mesh
        name="Cylindrical_Mountain005 far right"
        castShadow
        receiveShadow
        geometry={nodes.Cylindrical_Mountain005.geometry}
        material={nodes.Cylindrical_Mountain005.material}
        position={[1.5, -0.06, -0.98]}
        // scale={0.03}
        scale={0.15}
      />
      <mesh
        name="Cylindrical_Mountain006 second from right"
        castShadow
        receiveShadow
        geometry={nodes.Cylindrical_Mountain006.geometry}
        material={nodes.Cylindrical_Mountain006.material}
        position={[2.25, -0.25, -2.01]}
        // scale={0.03}
        scale={0.15}
      />
      <mesh
        name="Cylindrical_Mountain006 back front"
        castShadow
        receiveShadow
        geometry={nodes.Cylindrical_Mountain006.geometry}
        material={nodes.Cylindrical_Mountain006.material}
        position={[2.2, -0.15, -3]}
        // scale={0.03}
        scale={0.4}
      />

      <mesh
        name="Spherical_Mountain first circle"
        castShadow
        receiveShadow
        geometry={nodes.Spherical_Mountain.geometry}
        material={nodes.Spherical_Mountain.material}
        position={[1.3, -0.275, -2]}
        // scale={[0.07, 0.05, 0.07]}
        scale={[0.35, 0.25, 0.35]}
      />
      <mesh
        name="Spherical_Mountain004 second circle"
        castShadow
        receiveShadow
        geometry={nodes.Spherical_Mountain004.geometry}
        material={nodes.Spherical_Mountain004.material}
        position={[1.15, -0.4, -3.5]}
        scale={[0.4, 0.3, 0.4]}
      />

      <mesh
        name="Cylindrical_Mountain004 back cylinder"
        castShadow
        receiveShadow
        geometry={nodes.Cylindrical_Mountain004.geometry}
        material={nodes.Cylindrical_Mountain004.material}
        position={[1.5, -0.16, -2.5]}
        // scale={0.05}
        scale={0.25}
      />
      <mesh
        name="Cylindrical_Mountain004 back cylinder"
        castShadow
        receiveShadow
        geometry={nodes.Cylindrical_Mountain004.geometry}
        material={nodes.Cylindrical_Mountain004.material}
        position={[1.5, -0.4, -5]}
        // scale={0.05}
        scale={0.5}
      />
      <mesh
        name="Spherical_Mountain002"
        castShadow
        receiveShadow
        geometry={nodes.Spherical_Mountain002.geometry}
        material={nodes.Spherical_Mountain002.material}
        position={[0.5, -0.375, -4]}
        // scale={[0.04, 0.02, 0.04]}
        scale={[0.4, 0.2, 0.4]}
      />
      {/* <mesh
        name="Spherical_Mountain003"
        castShadow
        receiveShadow
        geometry={nodes.Spherical_Mountain003.geometry}
        material={nodes.Spherical_Mountain003.material}
        position={[0.17, -0.09, -2]}
        // scale={[0.02, 0.02, 0.02]}
        scale={[0.1, 0.1, 0.1]}
      /> */}
    </group>
  );
};

export default memo(LeftMountain);
