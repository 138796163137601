import './index.css';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import App from './App.js';
import { ethers } from 'ethers';
import { Provider } from 'react-redux';
import { store } from './state/store';
import OpenSea from './pages/opensea';


function Root() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/opensea/scene/:seedId">
          <Route index element={<OpenSea />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Root />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

