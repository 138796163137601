import { useTexture } from '@react-three/drei';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  buildModelFromSeed,
  createModelFromUrl,
  createUrlFromState,
  randomizeFriendsie,
  randomObjectKey,
  restoreFromPreviousSeed,
  statePreloader,
  traitCategoryLogic,
} from '../builder_components/sceneutils';
import { setDisableUndoTimer, setFriendsieReservedTimer } from '../state/user/userSlice';
import { store } from '../state/store';

// initial assets from API for `preview` mode
export const fetchInitialData = async (
  builderState,
  authToken,
  setAvailableAssets,
  searchParams,
  setSearchParams,
  setFriendsieState,
  setTraitCategoryOptions,
  setPreloadedRandomState
) => {
  let headers =
    builderState === 'building' && authToken
      ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        }
      : {
          'Content-Type': 'application/json',
        };

  try {
    const assetResult = await axios.get(`${process.env.REACT_APP_OPENSEA_SERVICE_URL}/assets/available`, {
      headers,
    });
    if (assetResult && assetResult.data) {
      let availableAssets = assetResult.data.assets;

      for (const key of Object.keys(availableAssets.face_dark)) {
        useTexture.preload(availableAssets.face_dark[key]['asset_url']);
      }
      for (const key of Object.keys(availableAssets.face_light)) {
        useTexture.preload(availableAssets.face_light[key]['asset_url']);
      }

      setAvailableAssets(availableAssets);

      // holder for the starting friendsie seed
      let initialFriendsieState;

      // not in builder mode. So either build from URL, or select randomly
      if (searchParams.toString()) {
        initialFriendsieState = createModelFromUrl(availableAssets, searchParams);
      } else {
        initialFriendsieState = randomizeFriendsie(availableAssets);
        setSearchParams(createUrlFromState(initialFriendsieState));
      }

      // preload new state
      statePreloader(initialFriendsieState);

      //set new state
      setFriendsieState(initialFriendsieState);
      setTraitCategoryOptions(traitCategoryLogic(availableAssets, 'head', initialFriendsieState));

      // preload and prepare the next random state
      let nextRandomState = randomizeFriendsie(availableAssets);

      // preload the next random state
      statePreloader(nextRandomState);
      setPreloadedRandomState(nextRandomState);
    }
  } catch (error) {
    console.log('axios error: ', error);
    toast.error('Failed to fetch assets');
  }
};

// Get starting assets from websocket when user enters builder
export const setInitialDataFromWebsocket = async (
  builderState,
  authToken,
  // setAvailableAssets,
  searchParams,
  setSearchParams,
  setFriendsieState,
  setTraitCategoryOptions,
  setPreloadedRandomState,
  availableAssets
) => {
  if (!availableAssets) return;

  let headers =
    builderState === 'building' && authToken
      ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        }
      : {
          'Content-Type': 'application/json',
        };

  // holder for the starting friendsie seed
  let initialFriendsieState;

  // if user is building, see if they have current seed
  //  if (builderState === 'building') {
  if (authToken) {
    try {
      const seedResult = await axios.get(`${process.env.REACT_APP_SERVICE_URL}/seeds/current`, {
        headers,
      });
      // user has built before and a current seed exists
      if (seedResult && seedResult.status === 200) {
        initialFriendsieState = restoreFromPreviousSeed(seedResult, availableAssets);

        // toast.success('Previous friendsie from builder restored!');
      }

      // user is building but hasnt built before, so set random seed
      else {
        initialFriendsieState = randomizeFriendsie(availableAssets);

        // update API with the users starting seed
        updateSeeds(authToken, builderState, initialFriendsieState);
      }
    } catch (error) {
      // user did not have initial seed, so set as random
      initialFriendsieState = randomizeFriendsie(availableAssets);

      // update API with the users starting seed
      updateSeeds(authToken, builderState, initialFriendsieState);
    }
  } else {
    // no auth token, so dont update seed
    initialFriendsieState = randomizeFriendsie(availableAssets);
  }

  // preload new state
  statePreloader(initialFriendsieState);

  //set new state
  setFriendsieState(initialFriendsieState);
  setSearchParams(createUrlFromState(initialFriendsieState));
  setTraitCategoryOptions(traitCategoryLogic(availableAssets, 'head', initialFriendsieState));

  // preload and prepare the next random state
  let nextRandomState = randomizeFriendsie(availableAssets);

  // preload the next random state
  statePreloader(nextRandomState);
  setPreloadedRandomState(nextRandomState);
};

export const createModelFromShare = async (seedId, setFriendsieState, setAvailableAssets, compressed) => {
  let headers = {
    'Content-Type': 'application/json',
  };

  try {
    const assetResult = await axios.get(`${process.env.REACT_APP_OPENSEA_SERVICE_URL}/assets/available?compressed=${compressed}`, {
      headers,
    });

    const seedResult = await axios.get(`${process.env.REACT_APP_OPENSEA_SERVICE_URL}/tokens/${seedId}`, {
      headers,
    });

    let huh = seedResult;

    if (assetResult && assetResult.data) {
      let availableAssets = assetResult.data.assets;

      setAvailableAssets(availableAssets);

      // holder for the starting friendsie seed
      let initialFriendsieState;

      // not in builder mode. So either build from URL, or select randomly
      initialFriendsieState = buildModelFromSeed(availableAssets, seedResult.data);

      // preload new state
      statePreloader(initialFriendsieState);

      //set new state
      setFriendsieState(initialFriendsieState);
    }
  } catch (error) {
    console.log('axios error: ', error);
    toast.error('Failed to fetch assets');
  }
};

export const updateSeeds = async (authToken, builderState, newFriendsieState) => {
  if (builderState !== 'building' || !authToken) {
    // toast.error('Update seed access denied');
    return;
  }

  let headFixed = newFriendsieState.headFixed === 'fixed';
  let fixedModel = newFriendsieState.fixedModel;
  let noSprout = newFriendsieState.headNoSprout;
  // headFixed, fixedModel, headNoSprout
  // new seed
  let requestBody = {
    sprout: !fixedModel && !noSprout ? newFriendsieState.sprout.id : '',
    body: !fixedModel && newFriendsieState.body.id,
    backpiece: !fixedModel && newFriendsieState.backpiece.id,
    hand: !fixedModel && newFriendsieState.accessory.id, // BUG ALERT - uses hand instead of accessory
    shoe: !fixedModel && newFriendsieState.shoe.id,
    head: newFriendsieState.head.id,
    face: !fixedModel && !headFixed ? newFriendsieState.face.id : '',
  };

  try {
    const result = await axios.post(`${process.env.REACT_APP_SERVICE_URL}/seeds/update`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (result && result.status === 200) {
      // toast.success('Seed updated');

      // if update is successful, reset the 10 minute "friendsie reserved" timer and "undo" timer

      store.dispatch(setFriendsieReservedTimer(600));
      store.dispatch(setDisableUndoTimer(30));
      return true;
    } else {
      toast.error('Failed to update seed. Please try again');
    }
  } catch (error) {
    // console.log('axios error: ', error);
    // toast.error('Failed to update seed');

    if (error.response && error.response.data) {
      let res = error.response;
      // console.log('seed update error: ', error);
      if (res.status === 400) {
        toast.error('Sorry, that option is unavailable. Please select something else.');
      } else if (res.status === 409) {
        let obj = res.data.available;
        let items = [];
        for (const property in obj) {
          if (!obj[property]) {
            items.push(property);
          }
          // console.log(`${property}: ${obj[property]}`);
        }
        let toastString = `Sorry, some assets are not available. Please select a different ${items
          .map((part, index) => `${part}${index === items.length - 1 ? '.' : `${items.length > 1 && ', '}`}`)
          .join('')}`;

        toast.error(toastString);
      } else if (res.status === 500) {
        toast.error('Internal server error response from API. Failed to update seed.');
      } else {
        toast.error('Failed to update seed. Please try again');
      }
    } else if (error.request) {
      // The request was made but no response was received
      toast.error('Failed to update seed. Please try again');
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error('Failed to update seed. Please try again');
    }
  }
};

export const undoSeed = async (authToken, builderState, newFriendsieState) => {
  if (builderState !== 'building' || !authToken) {
    toast.error('Update seed access denied');
    return;
  }

  // new seed
  let requestBody = {
    sprout: newFriendsieState.sprout.id,
    body: newFriendsieState.body.id,
    backpiece: newFriendsieState.backpiece.id,
    hand: newFriendsieState.accessory.id, // uses hand instead of accessory
    shoe: newFriendsieState.shoe.id,
    head: newFriendsieState.head.id,
    face: newFriendsieState.face.id,
  };

  try {
    const result = await axios.post(`${process.env.REACT_APP_SERVICE_URL}/seeds/undo`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (result && result.status === 200) {
      // toast.success('Undo success');
      return true;
    }
  } catch (error) {
    console.log('axios error: ', error);
    toast.error('Failed to undo seed');
  }
};

export const getActiveUsers = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_SERVICE_URL}/stats`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (result && result.status === 200) {
      return result.data.building;
    }
  } catch (error) {}
};
